// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';
import { useSettingsContext } from 'src/components/settings';
// routes

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Footer() {
  const settings = useSettingsContext();
  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        {settings.companyFooterText || settings.companyFooterHtml ? (
          <>
            {settings.companyFooterHtml && (
              <div dangerouslySetInnerHTML={{ __html: `${settings.companyFooterHtml}` }} />
            )}
            {settings.companyFooterText && (
              <Typography variant="caption" component="div">
                © All rights reserved
                <br /> made by
                <Link
                  href={
                    settings.companyFooterLink.startsWith('http')
                      ? settings.companyFooterLink
                      : `https://${settings.companyFooterLink}`
                  }
                >
                  {' '}
                  {settings.companyFooterText}{' '}
                </Link>
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="caption" component="div">
            © All rights reserved
            <br /> made by
            <Link href="https://unisot.com/"> UNISOT AS </Link>
          </Typography>
        )}
      </Container>
    </Box>
  );

  return simpleFooter;
}
